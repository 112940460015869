// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/AmountAdjuster.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/AmountAdjuster.tsx");
  import.meta.hot.lastModified = "1734124776000";
}
// REMIX HMR END

import React, { useEffect, useState } from 'react';
export const AmountAdjuster = ({
  qtyInCart,
  baseUnitOfMeasure,
  increaseBy,
  inCart,
  addToCart,
  adjustInCart,
  deleteFromCart,
  cssPostFix,
  showUnit
}) => {
  _s();
  const [quantityInput, setQuantityInput] = useState('0');
  useEffect(() => {
    setQuantityInput(formatQuantity(qtyInCart, baseUnitOfMeasure));
  }, [qtyInCart, baseUnitOfMeasure]);
  const formatQuantity = (quantity, unit) => isNaN(quantity) || quantity < 0 ? '0' : unit === 'KG' ? (quantity / 1000).toFixed(3) : quantity.toString();
  const parseQuantity = (value, unit) => unit === 'KG' ? parseFloat(value) * 1000 : parseInt(value, 10);
  const handleIncrease = () => {
    const increment = baseUnitOfMeasure === 'KG' ? increaseBy : 1;
    const newQuantity = inCart ? qtyInCart + increment : increment;
    if (!inCart) {
      addToCart(newQuantity);
    } else {
      adjustInCart(newQuantity);
    }
  };
  const handleDecrease = () => {
    const decrement = baseUnitOfMeasure === 'KG' ? increaseBy : 1;
    const newQuantity = Math.max(0, qtyInCart - decrement);
    if (newQuantity === 0 && inCart) {
      deleteFromCart();
    } else {
      adjustInCart(newQuantity);
    }
  };
  const handleBlur = () => {
    const parsedValue = parseQuantity(quantityInput, baseUnitOfMeasure);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      if (!inCart && parsedValue > 0) {
        addToCart(parsedValue);
      } else if (inCart) {
        adjustInCart(parsedValue);
      }
    } else {
      setQuantityInput(formatQuantity(qtyInCart, baseUnitOfMeasure));
    }
  };
  const handleChange = e => {
    const value = e.target.value;
    setQuantityInput(value === '' || isNaN(parseFloat(value)) ? '0' : value);
  };
  return <div className="input-and-checkbox-container">
      <div className={`input-group${cssPostFix}`}>
        <button type="button" onClick={handleDecrease}>
          -
        </button>
        <input type="number" step={baseUnitOfMeasure === 'KG' ? '0.010' : '1'} min="0" value={quantityInput} onChange={handleChange} onBlur={handleBlur} />
        {showUnit && <div className="quantity-text"> {baseUnitOfMeasure}</div>}
        <button type="button" onClick={handleIncrease}>
          +
        </button>
      </div>
    </div>;
};
_s(AmountAdjuster, "tIW5yluH4oIgMNLRqAsVj55n3Cc=");
_c = AmountAdjuster;
var _c;
$RefreshReg$(_c, "AmountAdjuster");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;