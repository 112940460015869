// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/products/ProgressBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/products/ProgressBar.tsx");
  import.meta.hot.lastModified = "1734124776000";
}
// REMIX HMR END

import React from 'react';
export default function ProgressBar({
  amountInPool,
  filledPools,
  maxValue,
  currentValue,
  color,
  infoTextClassname,
  baseUnit,
  takeRest
}) {
  const progressValue = amountInPool % maxValue / maxValue * 100;
  color = progressValue < 100 ? 'custom-progress-bar-yellow' : 'custom-progress-bar-all-in';
  let infoText = '';
  let className = '';
  if (amountInPool % maxValue >= maxValue) {
    infoText = 'Bestellmenge erreicht';
  } else if (takeRest) {
    infoText = 'Rest wird übernommen, wenn Menge nicht erreicht wurde';
    className = 'takeRest';
  } else {
    const remainingValue = maxValue - amountInPool % maxValue;
    const unitText = baseUnit === 'KG' ? 'kg bis zur Bestellung' : 'Stk damit 10% wegfallen';
    infoText = `Noch ${Number(remainingValue).toFixed(2)}${unitText}`;
  }
  return <div className="w-full">
      <div className="w-full">
        <div className="text-green-700">Gefüllte Pools: {filledPools}</div>
        <div> Aktueller Stand: {amountInPool}</div>
        <div data-testid="progress-bar-container" className={`w-full h-4 rounded-full overflow-hidden ${className}`}>
          <div data-testid="progress-bar" className={`h-full ${color} transition-all rounded-full duration-500 ease-in-out`} style={{
          width: `${progressValue}%`
        }}></div>
        </div>
      </div>
      <div className="w-full">
        <span className={`${infoTextClassname}`}>{infoText}</span>
      </div>
    </div>;
}
_c = ProgressBar;
var _c;
$RefreshReg$(_c, "ProgressBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;